<template>
  <v-dialog
    v-model="dialog"
    max-width="550"
    persistent
  >
    <v-card>
      <v-toolbar>
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%"
        >
          <h3 class="font-weight-regular text-uppercase">
            {{ $t("promotions.overlapping_promotions") }}
          </h3>
          <v-spacer></v-spacer>
          <div>
            <v-icon @click="cancel">mdi-close</v-icon>
          </div>
        </div>
      </v-toolbar>
      <v-card-text class="pa-4 black--text">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("name") }}</th>
                <th class="text-left">{{ $t("start_date") }}</th>
                <th class="text-left">{{ $t("end_date") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in promotions"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.start_date }}</td>
                <td>{{ item.end_date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p>{{ $t("promotions.schedule") }}</p>
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          class="body-2 font-weight-bold"
          @click="cancel"
          >{{ $t("promotions.change_dates") }}</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click="agree"
          >{{ $t("promotions.submit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      promotions: [],
    };
  },

  methods: {
    open(promotions) {
      this.promotions = promotions;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>